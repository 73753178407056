import { VehicleContextForPrint } from './../models/vehicle-context.model';
import { EnvironmentService } from './../services/environment.service';
import { TimeBasedService } from './../models/time-based-service.model';
import { Component, OnInit, HostListener, ViewEncapsulation, Renderer2, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CloudService } from './../services/cloud.service';
import { MaintenancePackage, MaintenanceIntervals } from './../models/maintenance-package.model';
import { MaintenanceLineItem } from './../models/maintenance-line-item.model';
import { CanDeactivateGuard } from '../guards/can-deactivate.guard';
import _ from 'lodash';
import { VehicleConfigLookupService } from './../services/vehicle-config-lookup.service';
import { UpgDialogComponent } from '../upg-dialog/upg-dialog.component';
import { EventReport } from '../models/event-report-model';


@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MaintenanceComponent implements OnInit {

  vehicleContextForPrint: VehicleContextForPrint

  allMaintenancePckgs: MaintenanceIntervals[] = [];
  selectedMaintenancePackage: MaintenanceIntervals;
  maintenanceLineItems: MaintenanceLineItem[] = [];
  timeBasedServices: TimeBasedService[] = [];

  currentYear = new Date().getFullYear();

  isMaintenanceRecommendedPackage = false;
  isFixedFooter = true;

  brand = 'vw';
  importerCode = '444';

  privacyUrl: string;
  contactUsUrl: string;

  recommendedPackageIndex = 0;
  eventReport: EventReport;

  screenHeight;

  constructor(private cloudService: CloudService, private vehicleConfigService: VehicleConfigLookupService,
    private environmentService: EnvironmentService, private router: Router, private location: Location,
    private route: ActivatedRoute, private guard: CanDeactivateGuard, private renderer: Renderer2,
    private dialog: MatDialog) {
    this.screenHeight = this.getViewportSize().h;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenHeight = this.getViewportSize().h;
    this.calculateScreenHeight();
  }

  ngOnInit() {
    console.log('=>=>=> VEHICLE CONTEXT NGONINIT MPTS- ', this.vehicleConfigService.getVehicleContext());
    this.initVariables();
    console.log('=>=>=> VEHICLE CONTEXT NGONINIT - ', this.vehicleConfigService.getVehicleContext());
    if (this.cloudService.getIsMenuClickedInFRM()) {
      this.loadMaintenancePackage();
    } else {
      this.timeBasedServices = this.vehicleConfigService.getTimeBasedServices();
      this.buildAllMaintenancePackages(this.vehicleConfigService.getAllMaintenancePackages());
    }
  }

  initVariables() {
    this.vehicleContextForPrint = this.vehicleConfigService.getVehicleContextForPrint();
    this.brand = this.environmentService.getBrand();
    this.importerCode = this.environmentService.getImporterCode();
    this.recommendedPackageIndex = 0;
    this.privacyUrl = this.environmentService.getPrivacyUrl();
    this.contactUsUrl = this.environmentService.getContactUsUrl();
    this.allMaintenancePckgs = [];
    this.selectedMaintenancePackage = { 'package_id': '', 'mileage': '', 'recommended': false, 'isSelected': false, maintenance_line_item: [], 'description': '' };
    this.environmentService.removeBackgroundImage();
    this.eventReport = this.vehicleConfigService.getEventReport();
    //document.body.style.backgroundColor = '#f1f5f6';
  }

  showMessage() {
    if (this.allMaintenancePckgs && parseInt(this.allMaintenancePckgs[this.allMaintenancePckgs.length - 1]['mileage']) < parseInt(this.vehicleContextForPrint?.mileage)) {
      return true;
    }
    return false;
  }

  loadMaintenancePackage() {
    this.allMaintenancePckgs = this.vehicleConfigService.getManipulatedMaintenanceIntervals();
    this.timeBasedServices = this.vehicleConfigService.getTimeBasedServices();
    let recommendedPackage = {};
    let index = 0;
    for (let interval of this.allMaintenancePckgs) {
      if (interval.isSelected) {
        this.selectedMaintenancePackage = interval;
        this.recommendedPackageIndex = index;
        break;
      } else if (interval.recommended) {
        recommendedPackage = interval;
        this.recommendedPackageIndex = index;
      }
      index = index + 1;
    }
    if (!_.isNil(this.selectedMaintenancePackage.package_id) && this.selectedMaintenancePackage.package_id.length > 0) {
      this.maintenanceLineItems = this.selectedMaintenancePackage['maintenance_line_item'];
    } else {
      this.maintenanceLineItems = recommendedPackage['maintenance_line_item'] || [];
    }
  }

  buildAllMaintenancePackages(maintenancePackages: MaintenancePackage[]) {

    maintenancePackages.forEach((maintenancePackage, index) => {
      maintenancePackage['maintenance_line_item'].forEach(lineItem => {
        lineItem['isExpanded'] = false;
      });
      this.allMaintenancePckgs.push({
        'package_id': maintenancePackage['id'],
        'mileage': ((maintenancePackage['interval'].value / 1000) + 'K'),
        'recommended': maintenancePackage['recommended'],
        'isSelected': maintenancePackage['recommended'],
        'maintenance_line_item': maintenancePackage['maintenance_line_item'],
        'description': maintenancePackage['description']
      });
      if (maintenancePackage['recommended']) {
        this.isMaintenanceRecommendedPackage = true;
        this.recommendedPackageIndex = index;
        this.maintenanceLineItems = maintenancePackage['maintenance_line_item'];
        this.selectedMaintenancePackage = {
          'package_id': maintenancePackage['id'],
          'mileage': ((maintenancePackage['interval'].value / 1000) + 'K'),
          'recommended': maintenancePackage['recommended'],
          'isSelected': maintenancePackage['recommended'],
          'maintenance_line_item': maintenancePackage['maintenance_line_item'],
          'description': maintenancePackage['description']
        };
      }
    });
    this.vehicleConfigService.setManipulatedMaintenanceIntervals(this.allMaintenancePckgs);
  }

  scrollToRecomendedInterval(recommendedPackageIndex) {
    let elmnt = document.getElementById('upgServiceTimeline');
    let myElement = document.getElementById(recommendedPackageIndex + '-MI');
    let topPos = myElement.offsetLeft;

    let view_port_width = this.getViewportSize().w;

    if (!_.isNil(view_port_width) && ((view_port_width >= 1024 && recommendedPackageIndex >= 7) ||
      (view_port_width >= 530 && recommendedPackageIndex >= 5) ||
      (view_port_width < 530 && recommendedPackageIndex >= 2))) {
      elmnt.scrollLeft = topPos - 50;
    }
    let listElement = document.getElementById('upgServiceTimelineList');
    listElement.style.width = (125 * this.allMaintenancePckgs.length) + 'px';
  }

  onClickLineItem(lineItem: MaintenanceLineItem) {
    lineItem.isExpanded = !lineItem.isExpanded;
    this.calculateScreenHeight();
  }

  onClickTimeBasedServiceLineItem(service: TimeBasedService) {
    service.isExpanded = !service.isExpanded;
    this.calculateScreenHeight();
  }

  loadMaintenancePackageById(packageId: string) {

    this.cloudService.saveAuditDetails(this.eventReport, 'Maintenance_Page_Click');

    this.clearSelection();

    this.selectedMaintenancePackage = this.allMaintenancePckgs.find(maintenancePackage => maintenancePackage.package_id === packageId);

    this.selectedMaintenancePackage.isSelected = true;

    this.maintenanceLineItems = this.selectedMaintenancePackage.maintenance_line_item;

    this.isMaintenanceRecommendedPackage = this.getPackageStatus(packageId);

    this.timeBasedServices.forEach((service => {
      if (service.notes.length > 1) {
        service.isExpanded = false;
      }
    }));

    this.calculateScreenHeight();
  }

  clearSelection() {
    this.allMaintenancePckgs.forEach((maintenancePackage) => {
      maintenancePackage['isSelected'] = false;
      maintenancePackage['maintenance_line_item'].forEach(lineItem => {
        lineItem['isExpanded'] = false;
      });
    });
  }

  calculateScreenHeight() {
    setTimeout(() => {
      this.isFixedFooter = document.documentElement.scrollHeight > this.screenHeight;
    });
  }

  getPackageStatus(packageId?: string) {
    for (let interval of this.allMaintenancePckgs) {
      if (interval.package_id === packageId && interval.recommended) return true;
    }
    return false;
  }

  isSelectedInterval(packageId: string): boolean {
    if (this.selectedMaintenancePackage) return packageId === this.selectedMaintenancePackage.package_id;
  }

  goBack(event?: any) {
    this.cloudService.setIsVehicleInContext(true);
    this.vehicleConfigService.setVehicleInContext(true);
    console.log('=>=>=> VEHICLE CONTEXT GOBACK - ', this.vehicleConfigService.getVehicleContext());
    this.router.navigate(['../']);
  }


  print(event) {
    event.preventDefault();
    setTimeout(() => window.print(), 100);
  }

  isIphone() {
    return /iPhone/.test(navigator.userAgent) && !window['MSStream'];
  }

  isIpad() {
    if (/\b(iPad)\b/.test(navigator.userAgent) && /WebKit/.test(navigator.userAgent) && !window['MSStream']) {
      return true;
    }
    return false;
  }

  getPrintTitle() {
    return (this.brand == 'A') ? 'Audi Maintenance Schedule' : 'VW Maintenance Schedule';
  }

  trackLineItems(index, item) {
    return index;
  }

  toTop() {
    setTimeout(() => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }

  getViewportSize(w?) {
    w = w || window;

    // Works for all browsers except IE8 and before
    if (w.innerWidth != null) return { w: w.innerWidth, h: w.innerHeight };

    // For IE (or any browser) in Standards mode
    var d = w.document;
    if (document.compatMode == 'CSS1Compat')
      return {
        w: d.documentElement.clientWidth,
        h: d.documentElement.clientHeight
      };

    // For browsers in Quirks mode
    return { w: d.body.clientWidth, h: d.body.clientHeight };
  }

  ngAfterViewInit() {
    this.calculateScreenHeight();
    this.toTop();
    this.scrollToRecomendedInterval(this.recommendedPackageIndex);
    this.cloudService.setIsMenuClickedInFRM(false);
  }

  onMenuClick() {
    this.cloudService.setIsMenuClickedInFRM(true);
    this.vehicleConfigService.setManipulatedMaintenanceIntervals(this.allMaintenancePckgs);
    this.guard.allow = true;
    this.router.navigateByUrl('menu', { skipLocationChange: true });
  }

  showPrintOption() {
    this.cloudService.saveAuditDetails(this.eventReport, 'Click_Print');
    this.dialog.closeAll();
    this.dialog.open(UpgDialogComponent, {
      data: {}
    });
  }

  onContactUsFooterSelect(type: string){
    if (type === 'PRIVACY_POLICY') {
      this.cloudService.saveAuditDetails(this.eventReport, 'Click_Privacy_Policy_in_Footer');
    } else {
      this.cloudService.saveAuditDetails(this.eventReport, 'Click_Contact_Us_in_Footer');
    }
  }

  ngOnDestroy() {
    //Garbage collect all subscriptions
    document.body.style.backgroundColor = '#FFFFFF';
  }
}
