import { VehicleConfigLookupService } from './services/vehicle-config-lookup.service';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
// import { CanDeactivateGuard, CanActivateGuard } from './guards/can-deactivate.guard';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';

import { CloudService } from './services/cloud.service';
import { MaintenanceComponent } from './packages/maintenance.component';
import { PopupComponent } from './common/popup.component';
import { HeaderComponent } from './common/header.component';
import { VinInputDirective } from './directives/vin-input.directive';
import { SearchComponent } from './search/search.component';
import { SpinnerComponent } from './common/spinner.component';
import { UrlInterceptorService } from '../app/interceptors/url-interceptor.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MenuComponent } from './menu/menu.component';
import { EnvironmentService } from './services/environment.service';
import { UpgDialogComponent } from './upg-dialog/upg-dialog.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

const routes: Routes = [
   { path: '', redirectTo: '/', pathMatch: 'full' },
   { path: 'search', component: SearchComponent },
   { path: ':brand', component: SearchComponent },
  //  { path: 'packages', component: MaintenanceComponent, canDeactivate: [ CanDeactivateGuard ] },
   { path: '**', component: SearchComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    MaintenanceComponent,
    PopupComponent,
    HeaderComponent,
    VinInputDirective,
    // SearchComponent,
    SpinnerComponent,
    MenuComponent,
    UpgDialogComponent
  ],
  // entryComponents: [UpgDialogComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatDialogModule
  ],
  providers: [
    CloudService,
    VehicleConfigLookupService,
    EnvironmentService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}
    // CloudService,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: UrlInterceptorService,
    //   multi: true
    // }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
