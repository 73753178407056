export const URL_MPMS_ORIGIN_DEV = 'https://dev.vwupgmaintenance.svc.vwusa.io';
export const URL_ORDERGUIDE_ORIGIN_DEV = 'https://dev.vwupgorderguide.svc.vwusa.io';
export const URL_OWNERS_LITERATURE_ORIGIN_DEV = 'https://dev.vwupgownersliterature.svc.vwusa.io';
export const URL_EVENT_SERVICE_ORIGIN_DEV = 'https://dev.vwupgevent.svc.vwusa.io';

export const URL_MPMS_ORIGIN_QA = 'https://qa.vwupgmaintenance.svc.vwusa.io';
export const URL_ORDERGUIDE_ORIGIN_QA = 'https://qa.vwupgorderguide.svc.vwusa.io';
export const URL_OWNERS_LITERATURE_ORIGIN_QA = 'https://qa.vwupgownersliterature.svc.vwusa.io';
export const URL_EVENT_SERVICE_ORIGIN_QA = 'https://qa.vwupgevent.svc.vwusa.io';

export const URL_MPMS_ORIGIN_PROD = 'https://maintenancepackage.vw.com';
export const URL_ORDERGUIDE_ORIGIN_PROD = 'https://orderguide.vw.com';
export const URL_OWNERS_LITERATURE_ORIGIN_PROD = 'https://ownersliterature.vw.com';
export const URL_EVENT_SERVICE_ORIGIN_PROD = 'https://prod.vwupgevent.svc.vwusa.io';

export const URL_MAINTENANCE_PCKG = '/maintenance-package-service/v1';
export const URL_ORDER_GUIDE_SERVICE = '/order-guide-service/v1';
export const URL_FIND_OWNERS_LITERATURE_DOCS = '/owners-literature-service/v1/documents';
export const URL_DOWNLOAD_OWNERS_LITERATURE_DOCS = '/owners-literature-service/v1/document';
export const URL_GET_WHITELIST_DATA = '/owners-literature-service/v1/white-list-models';
export const URL_POST_EVENT = '/event-service/v1/event';

export const URL_MODEL_YEARS = '/model-years';
export const URL_CAR_LINES = '/car-lines';
export const URL_MODEL_TRIMS = '/model-trims';
export const URL_MODEL_TRIM = '/model-trim';
export const URL_PACKAGES = '/packages';
export const URL_VEHICLE_INFORMATION = '/vehicle-information';

export const URL_PRIVACY_POLICY_VW = 'https://www.vw.com/en/privacy.html';
export const URL_PRIVACY_POLICY_CAN_EN_VW = 'https://www.vw.ca/en/privacy-policy.html';
export const URL_PRIVACY_POLICY_CAN_FR_VW = 'https://www.vw.ca/fr/politique-de-confidentialite.html';
export const URL_PRIVACY_POLICY_AUDI = 'https://www.audiusa.com/us/web/en/compliance/privacy.html';
export const URL_PRIVACY_POLICY_CAN_EN_AUDI = 'https://www.audi.ca/ca/web/en/en-b.html?partner_id=&' +
'utm_expid=.S1GUvQ_TRMGJ3HONJ8pIiA.1&utm_referrer=https%3A%2F%2Fwww.audi.ca%2Fca%2Fweb%2Fen%2Fen-b.html' +
'%3Fpartner_id%3D#layer=/ca/web/en/tools/navigation/layer/legal/privacy.html';
export const URL_PRIVACY_POLICY_CAN_FR_AUDI = 'https://www.audi.ca/ca/web/fr/fr-b.html?partner_id=&' +
'utm_expid=.kFOwpcRdRlW0dqpnDYQ6aw.1&utm_referrer=https%3A%2F%2Fwww.audi.ca%2Fca%2Fweb%2Ffr%2Ffr-b.html' +
'#layer=/ca/web/fr/tools/navigation/layer/clause-de-non-responsabilite/confidentialite.html';

export const URL_CONTACT_US_VW = 'https://www.vw.com/en/contact.html?utm_source=VWServiceandParts.com&utm_medium=Footer&utm_campaign=Link';
export const URL_CONTACT_US_CAN_EN_VW = 'https://feedback.vwmodels.ca/?lang=en';
export const URL_CONTACT_US_CAN_FR_VW = 'https://feedback.vwmodels.ca/?lang=fr';
export const URL_CONTACT_US_AUDI = 'https://www.audiusa.com/us/web/en/support/contact-us.html';
export const URL_CONTACT_US_CAN_EN_AUDI = 'https://www.audi.ca/ca/web/en/contact-us.html';
export const URL_CONTACT_US_CAN_FR_AUDI = 'https://www.audi.ca/ca/web/fr/contact-us.html';

export const URL_CUSTOMER_CARE_VW = 'https://www.vw.com/en/contact.html?utm_source=VWServiceandParts.com&utm_medium=Footer&' +
'utm_campaign=Link';
export const URL_CUSTOMER_CARE_AUDI = 'https://www.audiusa.com/us/web/en/support/contact-us.html';
export const URL_CUSTOMER_CARE_CAN_EN_VW = 'https://feedback.vwmodels.ca/?lang=en';
export const URL_CUSTOMER_CARE_CAN_FR_VW = 'https://feedback.vwmodels.ca/?lang=fr';
export const URL_CUSTOMER_CARE_CAN_EN_AUDI = 'https://www.audi.ca/ca/web/en/contact-us.html';
export const URL_CUSTOMER_CARE_CAN_FR_AUDI = 'https://www.audi.ca/ca/web/fr/contact-us.html';

export const URL_CA_EMISSION_PART_LIST_VW = 'https://www.vw.com/CA-emissions-warranty';
export const URL_CA_EMISSION_PART_LIST_AUDI = 'https://www.vw.com/AUDI/CA-emissions-warranty';

export const URL_OWNERS_MANUAL_VW = 'https://www.vwserviceandparts.com/digital-resources/online-owners-manual/';

//AMS : RR15495253 - Audi Online Owner's Manual Site not secure- changed url from http to https - Prod deployment 16th July 2023
export const URL_OWNERS_MANUAL_AUDI = 'https://ownersmanual.audiusa.com/';
export const URL_ORDER_OWNERS_LITERATURE_VW = 'https://literature.vw.com';
export const URL_ORDER_OWNERS_LITERATURE_AUDI = 'https://literature.audiusa.com';

export const URL_CANADA_OWNERS_MANUAL_VW = 'https://www.vw.ca/en/owners-and-drivers/owner-s-manual.html';
export const URL_CANADA_OWNERS_MANUAL_AUDI = 'https://my.audi.com/vehicle/dashboard/';

export const CONST_AUDI = 'audi';
export const CONST_VW = 'vw';

export const ODOMETER_UNIT = 'MI';

export const CONST_CA_EMISSIONS_DOCTYPE = 'CA EMISSIONS';
export const CONST_WARRANTY_DOCTYPE = 'WARRANTY';

//export const CONST_ABRIDGE_OWNER_DOCTYPE = "Abridged Owner's Manual";
export const CONST_ABRIDGE_OWNER_DOCTYPE = "ABRIDGED OWNER'S MANUAL";

export const CONST_TIRE_WARRANTY_DOCTYPE = 'TIRE WARRANTY';
export const CONST_OWNERS_MANUAL_DOCTYPE = 'OWNERS_MANUAL';
export const CONST_ORDER_LITERATURE_DOCTYPE = 'ORDER_LITERATURE';
export const CONST_CA_EMISSIONS_PARTS_DOCTYPE = 'CA_EMISSIONS_PARTS';
export const CONST_MAINTENANCE_RESPONSE_TYPE = 'MAINTENANCE';
export const CONST_OWNERS_LITERATURE_RESPONSE_TYPE = 'OWNERS_LITERATURE';
export const CONST_MAINTENANCE_CARD_DOCTYPE = 'Maintenance Card';
export const CONST_BATTERY_CHARGING_CABLE_MANUAL = "BATTERY_CHARGING_CABLE_MANUAL";
export const CONST_COLLISION_BROCHURE_DOCTYPE = "COLLISION";
export const CONST_ROADSIDE_BROCHURE_DOCTYPE = "ROADSIDE";

export const CONST_EN_LANG = 'en';
export const CONST_FR_LANG = 'fr';
export const CONST_EN_US_LOCALE = 'en_US';
export const CONST_EN_CA_LOCALE = 'en_CA';
export const CONST_FR_CA_LOCALE = 'fr_CA';
export const CONST_EN_US_LANG_CODE = 'en-US';
export const CONST_EN_CA_LANG_CODE = 'en-CA';
export const CONST_FR_CA_LANG_CODE = 'fr-CA';
export const CONST_IMPORTER_CODE_CA = '999';
export const CONST_IMPORTER_CODE_US = '444';
export const CONST_BRAND_VW = 'V';
export const CONST_BRAND_AUDI = 'A';

export const CONST_APP_ORIGIN_US_VW = 'maintenance.vw.com';
export const CONST_APP_ORIGIN_CA_VW = 'maintenance.vw.ca';
export const CONST_APP_ORIGIN_US_AUDI = 'maintenance.audiusa.com';
export const CONST_APP_ORIGIN_CA_AUDI = 'audimaintenance.ca';
export const CONST_APP_ORIGIN_CA_AUDI_QA = 'qa.maintenance.audiusa.com';

export const CONST_APP_TITLE_VW = 'VW Maintenance Schedule';
export const CONST_APP_TITLE_AUDI = 'Audi Maintenance Schedule';


