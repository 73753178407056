import { MenuComponent } from './menu/menu.component';
import { PackagesGuard } from './packages.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { MaintenanceComponent } from './packages/maintenance.component';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: '/search', pathMatch: 'full' },
  { path: 'search', component: SearchComponent,
  title: 'Search'
 },
  /*, children : [
    // {
    //   path: '',
    //   redirectTo: '/search',
    //   pathMatch: 'full',
    // },
    { path: ':languageCode/packages', component: MaintenanceComponent, canActivate: [PackagesGuard] }
    // { path: '**', redirectTo: '/search' }
  ]},**/
  { path: 'search/:languageCode', component: SearchComponent},
  { path: 'search/:languageCode/:brand', component: SearchComponent},
  { path: 'search/:languageCode/:brand/:importerCode', component: SearchComponent},
  { path: 'packages', component: MaintenanceComponent, canActivate: [PackagesGuard],
    title: 'Packages'
  },
  { path: 'menu', component: MenuComponent, canActivate: [CanDeactivateGuard],
    title : 'Menu'
  },
  // { path: ':brand', component: SearchComponent },
  // { path: ':brand/:importerCode', component: SearchComponent},
  // , canDeactivate: [ CanDeactivateGuard ]
  { path: '**', component: SearchComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [SearchComponent];
